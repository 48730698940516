
import React, { useState, useEffect } from 'react';
import './App.css';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { SelectChangeEvent } from '@mui/material/Select';
import Container from '@mui/material/Container';

import Form from './components/Form'
import Description from './components/Description'
import Loader from './components/Loader/Loader'
import { Data, DescriptionData, ResponceClassifyText, OpenAiResponce } from './types';

import { createCompletion, classifyText } from './api/api'
import { sumConfidences } from './utils/helper'
import useIsDisabled from './hooks/useIsDisabled'
import { NUMBER_REQUESTS_TO_OPENAI } from './consts'

const initialData = {
  title: '',
  type: 'application',
  keys: '',
  characters: 500
}

const initialDescriptionData: DescriptionData = {
  max: {
    text: '',
    categories: []
  },
  maxSum: {
    text: '',
    categories: []
  }
}

function App() {
  const [description, setDescription] = useState<DescriptionData>(initialDescriptionData)
  const [data, setData] = useState<Data>({...initialData})
  const [isDisabled, setIsDisabled] = useIsDisabled(data)
  const [isLoading, setIsLoading] = useState(false)
  const [responces, setResponces] = useState<Promise<OpenAiResponce>[]>([])

  const handleChangeSelect = (event: SelectChangeEvent) => {
    setData({...data, type: event.target.value });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({...data, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    if (!responces.length) return
    let desc = initialDescriptionData
    // обработка ответов от openai
     Promise.allSettled(responces).then((res) => {
      let promisesText: Promise<ResponceClassifyText>[] = []
      let textDescription = '';
      res.filter(r => r.status !== 'rejected').forEach((text) => {
        if (text.status === 'fulfilled') {
          // @ts-ignore
          textDescription = text.value.choices[0].message.content
          promisesText.push(classifyText(textDescription))
        }
      })
      // обработка ответов от google
      Promise.allSettled(promisesText).then(resText => {
        resText.forEach(resTextItem => {
          if (resTextItem.status !== 'fulfilled') return
          if (!desc.max.categories.length) {
            desc.max.text = textDescription
            desc.max.categories = [...resTextItem.value.categories]
            desc.maxSum.text = textDescription
            desc.maxSum.categories = [...resTextItem.value.categories]
          }
          if (desc.max.categories[0]?.confidence < resTextItem.value.categories[0]?.confidence) {
            desc.max.text = textDescription
            desc.max.categories = resTextItem.value.categories
          }
          if (sumConfidences(desc.maxSum.categories) < sumConfidences(resTextItem.value.categories)) {
            desc.maxSum.text = textDescription
            desc.maxSum.categories = resTextItem.value.categories
          }
        })
      }).finally(() => {
        setDescription({...description, ...desc})
        setIsDisabled(false)
        setIsLoading(false)
      })
     })
}, [responces])

  const onGenerate = async () => {
    let text = `Generate description for ${data.title} ${data.type} using words ${data.keys}`
    setIsDisabled(true)
    setIsLoading(true)
    let count = NUMBER_REQUESTS_TO_OPENAI
    while (count) {
      setResponces( (arr) => {
        return [...arr,
          createCompletion(text, data.characters)]
      })
      count--
    }
   
  }

  return (
    <div className="App">
      <Container fixed>
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{minHeight: '100vh'}}
          >
            <Paper elevation={3} sx={{ width: '100%' }}>
              <Stack direction={{ sm: 'column', md: 'row' }}>
                <Form
                  handleChangeSelect={handleChangeSelect}
                  handleChange={handleChange}
                  onGenerate={onGenerate}
                  data={data}
                  isDisabled={isDisabled}
                />
                <Description  description={description} />
              </Stack>
            </Paper>
        </Stack>
      </Container>
      {isLoading && <Loader />}
    </div>
  );
}

export default App;
