import React, { useState, useEffect } from 'react'
import { Data } from '../types'

const useIsDisabled  = (data: Data) => {
    const [isDisabled, setIsDisabled] = useState(true)

    useEffect(() => {
        if (data.characters > 4000 || data.characters < 30) {
            setIsDisabled(true)
            return
        }
        for (let k in data) {
          if (!data[k as keyof Data]) {
            setIsDisabled(true)
            return
          }
        }
        !Object.keys(data).length ? setIsDisabled(true) : setIsDisabled(false)
    }, [data])

    return [isDisabled, setIsDisabled] as [boolean, typeof setIsDisabled]

}

export default useIsDisabled;
