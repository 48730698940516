import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import './Loader.css';

const Loader = () => {
    return (
        <Box className="loader">
            <CircularProgress />
            <div style={{marginTop: '20px', fontSize: '20px'}}><h3>Writing a description, this may take some time</h3></div>
        </Box>
    )
}

export default Loader;
