import React, { useEffect, useState } from 'react';
import '../App.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Autocomplete from '@mui/material/Autocomplete';
import { Data } from '../types'

import { CATEGORIES } from '../consts';

interface Form {
  handleChangeSelect: (e: SelectChangeEvent) => void,
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onGenerate: () => void,
  data: Data,
  isDisabled: boolean,
  // autocompleteChange: (e: React.SyntheticEvent<Element, Event>) => void
}

const Form = (props: Form) => {

  return (
    <FormControl className="form" sx={{padding: 5}}>
        <Box mb={4}>
          <TextField name="title" label="Title" variant="outlined" required fullWidth onChange={props.handleChange} />
        </Box>
        <Box mb={4} style={{position: 'relative'}}>
          <InputLabel id="type-select-label">Type</InputLabel>
          <Select
            labelId="type-select-label"
            id="demo-simple-select"
            value={props.data.type || ''}
            label="Type"
            onChange={props.handleChangeSelect}
            fullWidth
          >
            <MenuItem value={'game'}>Game</MenuItem>
            <MenuItem value={'application'}>Application</MenuItem>
          </Select>
        </Box>
        <Box mb={4}>
          <TextField name="keys" label="Keys separated by commas" variant="outlined" multiline required fullWidth onChange={props.handleChange} />
        </Box>
        <Box mb={4}>
          <TextField name="characters" label="Max number of characters in the text (min 30 max 4000)" variant="outlined"
              required
              fullWidth
              type="number"
              value={props.data.characters}
              onChange={props.handleChange}
              inputProps={{ min: "30", max: "4000" }}
              error={props.data.characters > 4000 || props.data.characters < 30}
            />
        </Box>
        {/* <Box mb={4}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={CATEGORIES}
            sx={{ width: "100%" }}
            onInputChange={props.autocompleteChange}
            renderInput={(params) => <TextField {...params} label="Categories" name="categories" required />}
            fullWidth
          />
        </Box> */}
        <Button variant="contained" size="large" type="submit" disabled={props.isDisabled} onClick={props.onGenerate}>Generate</Button>
    </FormControl>
  );
}

export default Form;
