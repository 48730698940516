import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import { DescriptionData } from '../types'

function formatString(str: string) {
    return str.replace(/\*\*(.*?)\*\*/g, '<br><strong>$1</strong>');
}

const Description = ({description}: {description: DescriptionData}) => {

    const getDescription = (d: DescriptionData) => {
        return (
            <div>
                <Box mb={4}>
                    <h3 style={{color: '#1976d2'}}>Max confidence:</h3>
                    {description.max.text}
                    {
                        description.max.categories.map(category => {
                            return (
                                <Box key={category.name} mt={1}>
                                    <div><strong>Сategory: </strong><i>{category.name}</i></div>
                                    <div><strong>Confidence: </strong><i>{category.confidence}</i></div>
                                </Box>
                            )
                        })
                    }
                </Box>
                <h3 style={{color: '#1976d2'}}>Max amount of confidence:</h3>
                <Box>
                    {description.maxSum.text}
                    {
                        description.maxSum.categories.map(category => {
                            return (
                                <Box key={category.name} mt={1}>
                                    <div><strong>Сategory: </strong><i>{category.name}</i></div>
                                    <div><strong>Confidence: </strong><i>{category.confidence}</i></div>
                                </Box>
                            )
                        })
                    }
                </Box>
            </div>
        )
    }

    return (
        <Box className={description.max.text ? 'description' : 'description isEmpty'} sx={{padding: 5}}>
            {description.max.text ? getDescription(description) : <strong className='description__empty'>Your description will appear here soon...</strong>}
        </Box>
    )
}

export default Description;
