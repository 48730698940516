
const API_KEY = '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';

export const createCompletion = async (content: string, characters: number) => {
    const url = "https://api.openai.com/v1/chat/completions";
    const body = {
      model: "gpt-4-turbo-preview",
      max_tokens: characters > 4000 ? 4000 : Number(characters),
      messages: [{role: "user", content }],
    };
    let result;
  
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${API_KEY}`
        },
        body: JSON.stringify(body)
      });
  
      result = await response.json();
    } catch (error) {
      console.error('Error:', error);
    }

    return result;
  }



export const classifyText = (text: string) => fetch('https://language.googleapis.com/v1/documents:classifyText?key=AIzaSyCy0tivqPWdpWdrutP1HiSvjvHvUzLXjkQ', {
  method: 'POST',
  body: JSON.stringify({
    document: {
      type: "PLAIN_TEXT",
      language: "en-us",
      content: text
    }
})}).then(res => res.json()).catch(err => console.error('Error:', err))
